// React
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Hero from '../Hero';
import Banner from '../resuable-components/Banner';

// Media
import Card1 from '../../assets/img/card1.png';
import Card2 from '../../assets/img/card2.png';
import Card3 from '../../assets/img/card3.png';
import Card4 from '../../assets/img/card4.png';
import Card5 from '../../assets/img/card5.png';
import Card6 from '../../assets/img/card6.png';

const Services = () => {
  return (
    <main className='services__container'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Leth Regnskab | Ydelser </title>
        <link rel='canonical' href='https://leth-regnskab.dk/services' />
        <meta
          name='description'
          content='Jeg tilbyder: Finansbogholderi, debitorbogholderi, kreditorbogholderi, lønassistance, afstemninger, regnskaber. Leth-Regnskab er en moderne virksomhed med speciale i bogholderi, regnskab, moms og lønindberetning'
        />
      </Helmet>
      <Hero title='Mine ydelser' />
      <section className='services__content__wrapper'>
        <div className='services__content__cards'>
          <div className='services__content__card'>
            <div className='card__image'>
              <img src={Card1} alt='Finansbogholderi stemningsbillede' />
            </div>
            <div className='card__text'>
              <h2>Finansbogholderi</h2>
              <p>
                Styr på konti i kontoplanen og alle bankens og kassens
                transaktioner.
              </p>
            </div>
          </div>
          <div className='services__content__card'>
            <div className='card__image'>
              <img src={Card2} alt='Debitorbogholderi stemningsbillede' />
            </div>
            <div className='card__text'>
              <h2>Debitorbogholderi</h2>
              <p>Fakturering, kontrol over betalinger og rykning.</p>
            </div>
          </div>
          <div className='services__content__card'>
            <div className='card__image'>
              <img src={Card3} alt='Kreditorbogholderi stemningsbillede' />
            </div>
            <div className='card__text'>
              <h2>Kreditorbogholderi</h2>
              <p>Betaling af regninger og opfølgning.</p>
            </div>
          </div>
          <div className='services__content__card'>
            <div className='card__image'>
              <img src={Card4} alt='Lønassistance stemningsbillede' />
            </div>
            <div className='card__text'>
              <h2>Lønassistance</h2>
              <p>Lønberegning i lønsystemer og opfølgning.</p>
            </div>
          </div>
          <div className='services__content__card'>
            <div className='card__image'>
              <img src={Card5} alt='Afstemninger stemningsbillede' />
            </div>
            <div className='card__text'>
              <h2>Afstemninger</h2>
              <p>
                Assistance ved afstemninger af banken, kassen, løn, moms, gæld,
                debitorer og kreditorer mm.
              </p>
            </div>
          </div>
          <div className='services__content__card'>
            <div className='card__image'>
              <img src={Card6} alt='Regnskaber stemningsbillede' />
            </div>
            <div className='card__text'>
              <h2>Regnskaber</h2>
              <p>
                Månedlige og -kvartalsrapporter, samt klargøring af årsregnskab
                til revisor.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Banner />
    </main>
  );
};

export default Services;
