// React
import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../Hero';

import Personal from '../../assets/img/personal.png';
import Banner from '../resuable-components/Banner';

const About = (props) => {
  return (
    <main className='about__container'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Leth Regnskab | Om mig</title>
        <link rel='canonical' href='https://leth-regnskab.dk/about' />
      </Helmet>
      <Hero title='Om mig' />
      <section className='about__content__wrapper'>
        <div className='about__content__infotext'>
          <p>Peg musen på billedet, for at læse lidt om mig.</p>
        </div>

        <div className='flip-card'>
          <div className='flip-card-inner'>
            <div className='flip-card-front'>
              <figure>
                <img src={Personal} alt='Betina Leth' />
              </figure>
            </div>
            <div className='flip-card-back'>
              <div className='flip-card-back__text'>
                <p>
                  <b>Lidt om mig selv:</b> <br /> Jeg har arbejdet med regnskab
                  i mange år og min mand har været selvstændig vognmand, hvor
                  jeg har klaret alt vedr. regnskab, løn og klargørelse til
                  revisor. <br /> <br />
                  <b>I 2005 fik jeg min uddannelse:</b> <br />
                  Kontoruddannelse med speciale Økonomi / Controller Efter min
                  tid på skolebænken og frem til sidste år har jeg arbejdet
                  mange spændende steder.
                  <br /> <br /> I 2021 blev jeg selvstændig, den bedste
                  beslutning angående mit arbejdsliv. Det er en fantastisk måde,
                  hvor jeg selv kan tilrettelægge mine arbejdsopgaver sammen med
                  mine kunder.
                </p>
                <p>
                  Jeg er altid klar til nye arbejdsopgaver og brænder for at
                  løse opgaverne for mine kunder.
                </p>
                <p>
                  Mine kunder spænder vidt fra softwarevirksomheder til
                  håndværkere. Jeg arbejder hjemmefra og kører gerne ud til mine
                  kunder, da jeg vægter personlig kundekontakt højt.
                  Leth-Regnskab klarer det tunge talarbejde for dig ift. dit
                  bogholderi. Jeg giver dig det overskud, du har brug for til at
                  kunne klø på med din virksomhed.
                </p>
                <p>
                  Jeg holder dig opdateret med de nyeste regler og love og
                  rådgiver dig om, hvad der gavner dig bedst – her og nu – og
                  fremadrettet.
                </p>
                <p>Jeg går op i mit arbejde med liv og sjæl.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Banner />
    </main>
  );
};

export default About;
