import React from 'react'
import { Link } from 'react-router-dom'
import { FaRegEnvelope, FaPhoneAlt } from 'react-icons/fa'
import Arrow from '../assets/img/arrow-right.svg'

const Footer = () => {
  return (
    <footer className='footer__container'>
      <div className='main__footer__wrapper'>
        <div className='main__footer__info'>
          <h4>Leth-Regnskab</h4>
          <p>Betina Leth Nielsen</p>
          <p>CVR: 42470554</p>
        </div>
        <div className='main__footer__links'>
          <h4>Genveje</h4>
          <ul>
            <Link to='/services'>
              <li>
                <img src={Arrow} alt='Arrow pointing right' />
                Ydelser
              </li>
            </Link>
            <Link to='/about'>
              <li>
                <img src={Arrow} alt='Arrow pointing right' />
                Om mig
              </li>
            </Link>
            <Link to='contact'>
              <li>
                <img src={Arrow} alt='Arrow pointing right' />
                Kontakt
              </li>
            </Link>
          </ul>
        </div>
        <div className='main__footer__contact'>
          <h4>Kontakt mig</h4>

          <div className='email'>
            <FaRegEnvelope />
            <a href='mailto:Leth-Regnskab@hotmail.com'>
              Leth-Regnskab@hotmail.com
            </a>
          </div>
          <div className='phone'>
            <FaPhoneAlt />
            <a href='tel:+4540116787'>+45 40 11 67 87</a>
          </div>
        </div>
      </div>
      <div className='secondary__footer'>
        <p>
          Leth-Regnskab
          <span> &copy; </span>
          {new Date().getFullYear()}. Alle rettigheder forbeholdes
        </p>
      </div>
    </footer>
  )
}

export default Footer
