// React
import React from 'react';
import { Helmet } from 'react-helmet';
import { useForm, ValidationError } from '@formspree/react';

import Hero from '../Hero';

const Contact = (props) => {
  const [state, handleSubmit] = useForm('xwkyqnjp');
  if (state.succeeded) {
    return <div className='email__message'>Tak for din besked!</div>;
  }
  return (
    <main className='contact__container'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Leth Regnskab | Kontakt</title>
        <link rel='canonical' href='https://leth-regnskab.dk/contact' />
      </Helmet>
      <Hero title='Kontakt' />
      <div className='contact__content__wrapper'>
        <div className='contact__form'>
          <form onSubmit={handleSubmit}>
            <div className='form__name'>
              <input required placeholder='Navn' type='text' name='Navn' />
              <input
                id='email'
                type='email'
                name='E-mail'
                placeholder='E-mail'
                required
              />
              <ValidationError
                prefix='Email'
                field='email'
                errors={state.errors}
              />
            </div>

            <div className='form__message'>
              <textarea id='message' name='Besked' placeholder='Besked' />
              <ValidationError
                prefix='Message'
                field='message'
                errors={state.errors}
                required
              />
            </div>
            <button type='submit'>Send</button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Contact;
