import React from 'react'
import logo from '../assets/img/logo.png'
import { Link, NavLink } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import { useState } from 'react'
import Scroll from '../assets/img/scroll-down.svg'

const Header = () => {
  const [isActive, setActive] = useState(false)

  const toggleNav = () => {
    setActive(!isActive)
  }

  return (
    <header>
      <nav className='navbar'>
        <FaBars onClick={toggleNav} />
        <div className='logo'>
          <Link to='/'>
            <img src={logo} alt='Logo' />
          </Link>
        </div>
        <div className='navbar__items'>
          <ul className={isActive ? 'active' : null}>
            <li>
              <NavLink className='navbar__item' to='/'>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className='navbar__item' to='/services'>
                Ydelser
              </NavLink>
            </li>
            <li>
              <NavLink className='navbar__item' to='/about'>
                Om mig
              </NavLink>
            </li>
            <li>
              <NavLink className='navbar__item' to='/contact'>
                Kontakt
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <img className='scroll' src={Scroll} alt='scroll down icon' />
    </header>
  )
}

export default Header
