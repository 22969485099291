import React from 'react';
const Hero = (props) => {
  return (
    <div className='hero__container'>
      <div className='heading'>
        <h1>{props.title}</h1>
        <h3>{props.slogan}</h3>
      </div>

      <h2>{props.text}</h2>
    </div>
  );
};

export default Hero;
