import React from 'react'
import './sass/main.scss'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Home from './components/pages/Home'
import Services from './components/pages/Services'
import About from './components/pages/About'
import Contact from './components/pages/Contact'
import Footer from './components/Footer'

function App() {
  return (
    <Router>
      <div className='container'>
        <Header />
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route path='/services' element={<Services />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  )
}

export default App
