import React from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <div className='banner__container'>
      <div className='banner__text'>
        <p>
          Har du spørgsmål, eller vil du gerne booke et møde? <br />
          Så tøv ikke med at kontakte mig nedenunder
        </p>
      </div>
      <div className='banner__btn'>
        <Link to='/contact'>
          <button className='contact'>Kontakt mig</button>
        </Link>
        <Link to='/services'>
          <button className='services'>Ydelser</button>
        </Link>
      </div>
    </div>
  );
};

export default Banner;
