import React from 'react';
// React
import { Helmet } from 'react-helmet';

// Components
import Hero from '../Hero';
import Banner from '../resuable-components/Banner';

// Media
import Iphone from '../../assets/img/iphone.png';

const Home = (props) => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Leth Regnskab</title>
        <link rel='canonical' href='https://leth-regnskab.dk' />
      </Helmet>
      <main className='home__container'>
        <Hero
          title='Leth Regnskab - '
          slogan='Intet er for småt eller for stort!'
          text='Jeg er din Freelance bogholder'
        />
        <div className='home__content__wrapper'>
          <div className='home__content__text'>
            <p>
              <span>Leth-Regnskab</span> er en moderne virksomhed med speciale i
              bogholderi, regnskab, moms og lønindberetning. Som ekstern
              partner, udfører jeg den komplette løsning for mine kunder, hele
              vejen rundt om bogholderi.
            </p>
            <ul>
              <li>
                Alt i bogføring i flere forskellige systemer som f.eks. NAV,
                E-conomics, Dinero, Billy og Dynamics 365 Business Central
              </li>
              <li>Debitor: Bogføring, fakturering, rykkere og afstemninger</li>
              <li>Kreditor: Bogføring, afstemninger og betalinger</li>
              <li>Moms opgørelse, afstemning og indberetning</li>
              <li>Lønindberetning: Danløn, Proløn og Bluegarden</li>
              <li>100% klargøring til revisor</li>
              <li>Selvangivelse enkeltmandsvirksomhed</li>
            </ul>
          </div>
          <div className='home__content__image'>
            <div className='image'>
              <img src={Iphone} alt='iphone - calculator' />
            </div>
          </div>
        </div>
        <Banner />
      </main>
    </>
  );
};

export default Home;
